.time-container {
  display: flex;
  width: 210px;
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 50%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 50%,
    transparent 100%
  );
  position: relative;
}

.time-container::after {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  height: 32px;

  background: rgb(234, 236, 238);
  border-radius: 5px;
  pointer-events: none;
  z-index: -1;
}

.time-items {
  /* width: 100%; */
  height: 100%;
  padding: 50% 0;
  margin: 0;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.time-items::-webkit-scrollbar {
  display: none;
}

.time-item {
  margin-right: 10px;
  list-style-type: none;
  width: 100%;
  text-align: right;
  scroll-snap-align: center;
}

.time-item div {
  display: inline-block;
}

.time-item-label {
  font-size: 28px;
}




.time-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.wheel-picker {
  height: 150px;
  overflow-y: auto;
  text-align: center;
  scroll-behavior: smooth; /* Smooth scrolling */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.wheel-item {
  padding: 10px;
  cursor: pointer;
}

.wheel-item.selected {
  background: #ddd;
}

.colon {
  font-size: 2rem;
}

#scroll-select-subject1 .scroll-select-list,
#scroll-select-subject2 .scroll-select-list,
.scroll-select-list {
  //-ms-overflow-style: none !important;
  //scrollbar-width: none !important;
  max-height: unset;
  height: 250px !important;
}

.scroll-item-container {
  width: 100px;
}
